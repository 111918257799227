@import "./assets/reset.css";
@import "../node_modules/ionicons/dist/css/ionicons.css";
@import "../node_modules/quill/dist/quill.snow.css";
@import "./assets/variables.css";
@import "./assets/quill.css";

/* Fixing box model */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

body,
input,
textarea {
  color: var(--uno-1);
  font-family: "Montserrat", "Helvetica Neue", sans-serif;
  font-weight: 300;
  background: var(--background);
}

/* textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  appearance: none;
} */

textarea {
  resize: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
  color: var(--duo-1);
}

h1 {
  font-size: 2rem;
  line-height: 1.45;
  margin-bottom: 1rem;
}

/* .CodeMirror {
  line-height: 1.45 !important;
  background: #faf8f5 !important;
} */

.splitter-layout > .layout-splitter:hover {
  background-color: var(--uno-5) !important;
}
.splitter-layout > .layout-splitter {
  background-color: var(--uno-5) !important;
}
