.View {
  display: flex;
  flex-grow: 1;
}

@media (min-width: 1024px) {
  .View .split {
    flex-basis: 50%;
    max-height: 100vh;
  }
}
