@import "./assets/variables.css";

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.Topbar {
  display: flex;
  background: var(--background);
  justify-content: space-between;
  border-bottom: 2px solid var(--uno-5);
  padding: 0 1rem;
  height: 44px;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 666;
}

.SearchField {
  width: 175px;
  border: 1px solid var(--uno-5);
  border-radius: 50px;
  padding: 0 10px;
  margin-right: 1rem;
  caret-color: var(--uno-4);
  transition: width 0.2s ease-in-out;
}

.SearchField:focus {
  width: 175px;
}

.Topbar__right {
  display: flex;
  align-items: center;
}

@media (max-width: 1023px) {
  body {
    padding-bottom: 50vh;
  }
  .SearchField {
    width: 100px;
  }

  .Topbar__login__info {
    display: none;
  }
}
