@import "../../assets/variables.css";

.Node {
  padding-left: 0.5rem;
}

.Node__text {
  display: flex;
  font-size: 18px;
  width: 100%;
  justify-content: space-between;
  line-height: 1.4;
  text-overflow: ellipsis;
  overflow: hidden; /* DONT TOUCH */
}

.Node__content {
  cursor: pointer;
  display: block;
}

.Node__icon {
  display: inline-block;
  min-width: 25px;
  color: var(--uno-4);
  text-decoration: none;
}

.Node__icon.hasNote {
  color: var(--uno-2);
}

.Node__input {
  font-size: 18px;
  line-height: 1.4;
  overflow: visible;
  padding: 0;
  border: none;
  flex-grow: 1;
}

.Node__input:focus {
  outline: none;
}

.Node__control {
  width: 25px;
  height: 25px;
  text-align: center;
  display: inline-block;
}

.Node__arrow {
  font-size: 25px;
  cursor: pointer;
  color: var(--uno-4);
}

.Node__dot {
  padding-top: 4px;
  color: var(--duo-1);
}

.Node__controls {
  max-width: 25px;
  max-height: 25px;
  margin-right: 3px;
  flex-shrink: 0;
}

.Node__controls::selection {
  background-color: transparent;
}

.Node__children {
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
}

.Node__children .Node:last-child {
  margin-bottom: 1rem;
}

.Node__title {
  margin-bottom: 10px;
  display: flex;
}
.Node__title.completed,
.Node__title.completed textarea {
  color: #7d768a;
}
.Node__title.completed .Node__icon {
  color: var(--uno-5);
}
.Node__title.completed .Node__icon.hasNote {
  color: #7d768a;
}
.Node__title.completed .Node__dot {
  color: var(--uno-2);
  color: #7d768a;
}

.Node__title.completed .Node__icon {
  display: none;
}
.Node__title.completed .Node__icon.Node__icon.hasNote {
  display: inline-block;
}

@media (min-width: 1024px) {
  .Node {
    padding-left: 1rem;
  }

  .Node__text {
    display: block;
    justify-content: flex-start;
  }

  .Node__content {
    display: inline;
  }

  .Node__arrow {
    padding-top: 3px;
    font-size: 18px;
  }

  .Node__children {
    padding-left: 1rem;
  }
}
