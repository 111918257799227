.ActionBar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  height: 44px;
  top: -44px;
  left: 0;
  width: 100vw;
  color: var(--uno-2);
  font-size: 24px;
  background: var(--background);
  border-bottom: 1px solid var(--uno-5);
  z-index: 777;
  transition: all 0.1s ease-in-out;
}
.ActionBar i {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
}

.ActionBar i.disabled {
  color: var(--uno-5);
}

.ActionBar.visible {
  top: 0;
}

@media (min-width: 1024px) {
  .ActionBar {
    display: none;
  }
}
