/*
     http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */ /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

:root {
  -webkit-tap-highlight-color: transparent;

  --main-black: #45526a;
  --main-border: #eaeaea;
  --main-background: #fdfdfd;
  --main-aggressive: #d17e7f;
  --main-light-secondary: #7b97c0;
  --main-desktop: 1025px;
  --main-gray: #b1bdcd;

  --main-Base03: #002b36;
  --main-Base02: #073642;
  --main-Base01: #586e75;
  --main-Base00: #657b83;
  --main-Base0: #839496;
  --main-Base1: #93a1a1;
  --main-Base2: #eee8d5;
  --main-Base3: #fdf6e3;
  --main-Yellow: #b58900;
  --main-Orange: #cb4b16;
  --main-Red: #dc322f;
  --main-Magenta: #d33682;
  --main-Violet: #6c71c4;
  --main-Blue: #268bd2;
  --main-Cyan: #2aa198;
  --main-Green: #859900;

  --main-Base03: #002b36;
  --main-Base02: #073642;
  --main-Base01: #586e75;
  --main-Base00: #657b83;
  --main-Base0: #839496;
  --main-Base1: #93a1a1;
  --main-Base2: #eee8d5;
  --main-Base3: #fdf6e3;
  --main-Yellow: #b58900;
  --main-Orange: #cb4b16;
  --main-Red: #dc322f;
  --main-Magenta: #d33682;
  --main-Violet: #6c71c4;
  --main-Blue: #268bd2;
  --main-Cyan: #2aa198;
  --main-Green: #859900;

  /* Duo Sea */
  --background: #1d262f;
  --uno-1: #d6e9ff;
  --uno-2: #88b4e7;
  --uno-3: #5d8cc0;
  --uno-4: #586f89;
  --uno-5: #444c55;
  --duo-1: #34febb;
  --duo-2: #32ae85;
  --duo-3: #42675a;

  /* Duo Dark */
  --background: #2c2734;
  --uno-1: #f1ebff;
  --uno-2: #cab2fa;
  --uno-3: #ae91e8;
  --uno-4: #715f95;
  --uno-5: #544d60;
  --duo-1: #fec38f;
  --duo-2: #b07745;
  --duo-3: #765f4c;

  /* Duo Forest */
  /* --background: #2a2d2a;
  --uno-1: #ddf8dd;
  --uno-2: #a9bca9;
  --uno-3: #869886;
  --uno-4: #738273;
  --uno-5: #585f58;
  --duo-1: #e7f98b;
  --duo-2: #99a659;
  --duo-3: #747a52; */

  /* Duo Earth */
  /* --uno-1: #ffdac2;
  --uno-2: #bd987f;
  --uno-3: #98755d;
  --uno-4: #705e51;
  --uno-5: #564b43;
  --duo-1: #fecb52;
  --duo-2: #b09045;
  --duo-3: #726546; */
}

.quill {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.ql-toolbar.ql-snow {
  border-radius: 3px;
  border: 1px solid var(--uno-5);
}

.ql-container.ql-snow {
  font-family: Avenir-light, sans-serif !important;
  font-size: 1.1rem;
  border: none;
}

.ql-editor ul > li::before {
  color: var(--duo-1);
}

.ql-editor {
  overflow: visible;
  line-height: 1.6;
  padding-left: 0;
  padding-right: 0;
}
.ql-editor.ql-blank {
  height: 50vh;
}
.ql-snow .ql-stroke {
  stroke: var(--uno-1);
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: var(--uno-1);
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: var(--duo-1);
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--duo-1);
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--duo-1);
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--duo-1);
}

@media (min-width: 1024px) {
  .ql-editor {
    padding-left: 12px;
    padding-right: 12px;
  }
}

/* Fixing box model */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

body,
input,
textarea {
  color: var(--uno-1);
  font-family: "Montserrat", "Helvetica Neue", sans-serif;
  font-weight: 300;
  background: var(--background);
}

/* textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  appearance: none;
} */

textarea {
  resize: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
  color: var(--duo-1);
}

h1 {
  font-size: 2rem;
  line-height: 1.45;
  margin-bottom: 1rem;
}

/* .CodeMirror {
  line-height: 1.45 !important;
  background: #faf8f5 !important;
} */

.splitter-layout > .layout-splitter:hover {
  background-color: var(--uno-5) !important;
}
.splitter-layout > .layout-splitter {
  background-color: var(--uno-5) !important;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.Topbar {
  display: flex;
  background: var(--background);
  justify-content: space-between;
  border-bottom: 2px solid var(--uno-5);
  padding: 0 1rem;
  height: 44px;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 666;
}

.SearchField {
  width: 175px;
  border: 1px solid var(--uno-5);
  border-radius: 50px;
  padding: 0 10px;
  margin-right: 1rem;
  caret-color: var(--uno-4);
  -webkit-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
}

.SearchField:focus {
  width: 175px;
}

.Topbar__right {
  display: flex;
  align-items: center;
}

@media (max-width: 1023px) {
  body {
    padding-bottom: 50vh;
  }
  .SearchField {
    width: 100px;
  }

  .Topbar__login__info {
    display: none;
  }
}

.Node {
  padding-left: 0.5rem;
}

.Node__text {
  display: flex;
  font-size: 18px;
  width: 100%;
  justify-content: space-between;
  line-height: 1.4;
  text-overflow: ellipsis;
  overflow: hidden; /* DONT TOUCH */
}

.Node__content {
  cursor: pointer;
  display: block;
}

.Node__icon {
  display: inline-block;
  min-width: 25px;
  color: var(--uno-4);
  text-decoration: none;
}

.Node__icon.hasNote {
  color: var(--uno-2);
}

.Node__input {
  font-size: 18px;
  line-height: 1.4;
  overflow: visible;
  padding: 0;
  border: none;
  flex-grow: 1;
}

.Node__input:focus {
  outline: none;
}

.Node__control {
  width: 25px;
  height: 25px;
  text-align: center;
  display: inline-block;
}

.Node__arrow {
  font-size: 25px;
  cursor: pointer;
  color: var(--uno-4);
}

.Node__dot {
  padding-top: 4px;
  color: var(--duo-1);
}

.Node__controls {
  max-width: 25px;
  max-height: 25px;
  margin-right: 3px;
  flex-shrink: 0;
}

.Node__controls::-moz-selection {
  background-color: transparent;
}

.Node__controls::selection {
  background-color: transparent;
}

.Node__children {
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
}

.Node__children .Node:last-child {
  margin-bottom: 1rem;
}

.Node__title {
  margin-bottom: 10px;
  display: flex;
}
.Node__title.completed,
.Node__title.completed textarea {
  color: #7d768a;
}
.Node__title.completed .Node__icon {
  color: var(--uno-5);
}
.Node__title.completed .Node__icon.hasNote {
  color: #7d768a;
}
.Node__title.completed .Node__dot {
  color: var(--uno-2);
  color: #7d768a;
}

.Node__title.completed .Node__icon {
  display: none;
}
.Node__title.completed .Node__icon.Node__icon.hasNote {
  display: inline-block;
}

@media (min-width: 1024px) {
  .Node {
    padding-left: 1rem;
  }

  .Node__text {
    display: block;
    justify-content: flex-start;
  }

  .Node__content {
    display: inline;
  }

  .Node__arrow {
    padding-top: 3px;
    font-size: 18px;
  }

  .Node__children {
    padding-left: 1rem;
  }
}

.Tree__list {
  width: 100vw;
  padding: 1rem;
  padding-left: 0.5rem;
  padding-top: 66px;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: flex-start;
  display: flex;
}

.Tree__list.split {
  display: none;
}

.Tree__editor {
  color: var(--uno-1);
  padding: 1rem;
  padding-top: 66px;
  overflow-y: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.Tree__editor__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Editor__header__close {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 46px;
  min-height: 46px;
  border-radius: 5px;
  cursor: pointer;
}

.Editor__header__close:hover {
  background: var(--uno-5);
}

.Editor__header__close i {
  line-height: 1;
  /* color: var(--main-Base01); */
}

.Tree__list__items {
  width: 100%;
}

.Header__link {
  text-decoration: none;
}

@media (min-width: 1024px) {
  .Tree__list {
    padding-left: 1rem;
    justify-content: center;
  }

  .Tree__list__items {
    flex-basis: 50vw;
    max-width: 50vw;
    flex-grow: 0;
    flex-shrink: 1;
  }

  .Tree__editor.split {
    flex-grow: 1;
    width: auto;
  }

  .Tree__list.split {
    display: block;
    flex-grow: 1;
    width: auto;
  }
}

@media (min-width: 1281px) {
  .Tree__list__items {
    width: 50vw;
  }
}

.Shortcuts {
  position: fixed;
  display: none;
  right: 2rem;
  color: var(--duo-3);
}

.Shortcuts h2 {
  margin-bottom: 1rem;
}

.Shortcuts__item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

@media (min-width: 1024px) {
  .Shortcuts {
    display: block;
  }
}

.ActionBar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  height: 44px;
  top: -44px;
  left: 0;
  width: 100vw;
  color: var(--uno-2);
  font-size: 24px;
  background: var(--background);
  border-bottom: 1px solid var(--uno-5);
  z-index: 777;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.ActionBar i {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
}

.ActionBar i.disabled {
  color: var(--uno-5);
}

.ActionBar.visible {
  top: 0;
}

@media (min-width: 1024px) {
  .ActionBar {
    display: none;
  }
}

.View {
  display: flex;
  flex-grow: 1;
}

@media (min-width: 1024px) {
  .View .split {
    flex-basis: 50%;
    max-height: 100vh;
  }
}

