:root {
  -webkit-tap-highlight-color: transparent;

  --main-black: #45526a;
  --main-border: #eaeaea;
  --main-background: #fdfdfd;
  --main-aggressive: #d17e7f;
  --main-light-secondary: #7b97c0;
  --main-desktop: 1025px;
  --main-gray: #b1bdcd;

  --main-Base03: #002b36;
  --main-Base02: #073642;
  --main-Base01: #586e75;
  --main-Base00: #657b83;
  --main-Base0: #839496;
  --main-Base1: #93a1a1;
  --main-Base2: #eee8d5;
  --main-Base3: #fdf6e3;
  --main-Yellow: #b58900;
  --main-Orange: #cb4b16;
  --main-Red: #dc322f;
  --main-Magenta: #d33682;
  --main-Violet: #6c71c4;
  --main-Blue: #268bd2;
  --main-Cyan: #2aa198;
  --main-Green: #859900;

  --main-Base03: #002b36;
  --main-Base02: #073642;
  --main-Base01: #586e75;
  --main-Base00: #657b83;
  --main-Base0: #839496;
  --main-Base1: #93a1a1;
  --main-Base2: #eee8d5;
  --main-Base3: #fdf6e3;
  --main-Yellow: #b58900;
  --main-Orange: #cb4b16;
  --main-Red: #dc322f;
  --main-Magenta: #d33682;
  --main-Violet: #6c71c4;
  --main-Blue: #268bd2;
  --main-Cyan: #2aa198;
  --main-Green: #859900;

  /* Duo Sea */
  --background: #1d262f;
  --uno-1: #d6e9ff;
  --uno-2: #88b4e7;
  --uno-3: #5d8cc0;
  --uno-4: #586f89;
  --uno-5: #444c55;
  --duo-1: #34febb;
  --duo-2: #32ae85;
  --duo-3: #42675a;

  /* Duo Dark */
  --background: #2c2734;
  --uno-1: #f1ebff;
  --uno-2: #cab2fa;
  --uno-3: #ae91e8;
  --uno-4: #715f95;
  --uno-5: #544d60;
  --duo-1: #fec38f;
  --duo-2: #b07745;
  --duo-3: #765f4c;

  /* Duo Forest */
  /* --background: #2a2d2a;
  --uno-1: #ddf8dd;
  --uno-2: #a9bca9;
  --uno-3: #869886;
  --uno-4: #738273;
  --uno-5: #585f58;
  --duo-1: #e7f98b;
  --duo-2: #99a659;
  --duo-3: #747a52; */

  /* Duo Earth */
  /* --uno-1: #ffdac2;
  --uno-2: #bd987f;
  --uno-3: #98755d;
  --uno-4: #705e51;
  --uno-5: #564b43;
  --duo-1: #fecb52;
  --duo-2: #b09045;
  --duo-3: #726546; */
}
