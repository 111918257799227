.Shortcuts {
  position: fixed;
  display: none;
  right: 2rem;
  color: var(--duo-3);
}

.Shortcuts h2 {
  margin-bottom: 1rem;
}

.Shortcuts__item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

@media (min-width: 1024px) {
  .Shortcuts {
    display: block;
  }
}
