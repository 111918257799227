@import "../../assets/variables.css";

.Tree__list {
  width: 100vw;
  padding: 1rem;
  padding-left: 0.5rem;
  padding-top: 66px;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: flex-start;
  display: flex;
}

.Tree__list.split {
  display: none;
}

.Tree__editor {
  color: var(--uno-1);
  padding: 1rem;
  padding-top: 66px;
  overflow-y: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.Tree__editor__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Editor__header__close {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 46px;
  min-height: 46px;
  border-radius: 5px;
  cursor: pointer;
}

.Editor__header__close:hover {
  background: var(--uno-5);
}

.Editor__header__close i {
  line-height: 1;
  /* color: var(--main-Base01); */
}

.Tree__list__items {
  width: 100%;
}

.Header__link {
  text-decoration: none;
}

@media (min-width: 1024px) {
  .Tree__list {
    padding-left: 1rem;
    justify-content: center;
  }

  .Tree__list__items {
    flex-basis: 50vw;
    max-width: 50vw;
    flex-grow: 0;
    flex-shrink: 1;
  }

  .Tree__editor.split {
    flex-grow: 1;
    width: auto;
  }

  .Tree__list.split {
    display: block;
    flex-grow: 1;
    width: auto;
  }
}

@media (min-width: 1281px) {
  .Tree__list__items {
    width: 50vw;
  }
}
